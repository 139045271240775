<template>
    <div>
        <v-select v-model="selected" :placeholder="`..Type to Select country..`" :options="countries"
            :reduce="country => country.name" label="name"></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import countries from '@/components/countries'
export default {
    data() {
        return {
            countries: countries,
            selected: null
        }
    },
    components: {
        vSelect
    },
    watch: {
        selected(value, oldValue) {
            this.$emit("countrySelected", value);
        }
    }
}
</script>

<style lang="css" scoped>
>>> {
    --vs-controls-color: #eeeeee;
    --vs-border-color: #424351;
    --vs-dropdown-bg: #282c34;
    --vs-dropdown-color: #eeeeee;
    --vs-dropdown-option-color: #eeeeee;
    --vs-selected-bg: #eeeeee;
    --vs-selected-color: #424351;
    --vs-search-input-color: #686968;
    --vs-dropdown-option--active-bg: #616362;
    --vs-dropdown-option--active-color: #eeeeee;
    --vs-border-color: #424351;
    --vs-border-width: 1px;
    --vs-border-style: solid;
    --vs-border-radius: 0px;
    --vs-font-size: .9375rem;
    --vs-line-height: 2.4rem;
}
</style>