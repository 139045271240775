<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo" style="display: flex; justify-content: center">
                <img src="../../public/images/logo3.png" alt="logo" style="width: 30%" />
              </div>
              <h4 style="font-weight:bolder">Sign Up</h4>
              <form class="pt-3" @submit.prevent="register">
                <div class="alert alert-warning alert-dismissible fade show" role="alert"
                  v-if="response.status == '03'">
                  <strong>{{ response.message }}</strong>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="alert alert-success alert-dismissible fade show" role="alert"
                  v-if="response.status == '00'">
                  <strong>{{ response.message }}</strong>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="text" class="form-control form-control-lg" name="fullName" id="fullname"
                      placeholder="Full name" v-model="fullName" style="color: #242323" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="email" class="form-control form-control-lg" name="email" id="email" placeholder="Email"
                      v-model="email" style="color: #242323" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="text" class="form-control form-control-lg" name="companyName"
                      placeholder="Company name" v-model="companyName" style="color: #242323" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <SelectComponent @countrySelected="countrySelected" />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <vue-tel-input :value="mobileNumber" @input="onInput" style="color:black"
                      class="form-control form-control-lg"></vue-tel-input>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="password" class="form-control form-control-lg" name="password" placeholder="Password"
                      v-model="password" style="color: #242323" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <div class="form-check form-check-flat form-check-secondary">
                      <label class="form-check-label font-weight-bold">
                        <input type="checkbox" class="form-check-input" />
                        I agree to all Terms & Conditions
                        <i class="input-helper"></i></label>
                    </div>
                  </div>
                </div>
                <div class="form-group row mt-0">
                  <div class="col-sm-12">
                    <button style="background-color: #1e1e2f; border: none"
                      class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-white"
                      type="submit">
                      SIGN UP
                      <span class="circle-loader" style="display: none" id="loader"></span>
                    </button>
                  </div>
                </div>
                <div class="text-center font-weight-strong">
                  Already have an account?
                  <router-link to="/login" class="text-black font-weight-bold">Login</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>
<script>
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import SelectComponent from '@/components/countrySelectComponent'

import baseUrl from "@/shared/baseUrl";
export default {
  components: {
    VueTelInput,
    SelectComponent
  },
  data() {
    return {
      fullName: "",
      email: "",
      mobileNumber: "",
      companyName: "",
      country: "",
      password: "",
      msg: "",
      response: {},
    };
  },
  methods: {
    countrySelected(country) {
      this.country = country;
    },
    register() {
      $("#loader").show();
      const user = {
        fullName: this.fullName,
        email: this.email,
        mobileNumber: this.mobileNumber,
        companyName: this.companyName,
        country: this.country,
        password: this.password,
      };
      if (
        this.fullName == "" ||
        this.email == "" ||
        this.mobileNumber == "" ||
        this.companyName == "" ||
        this.country == "" ||
        this.password == ""
      ) {
        this.response.message = "Please complete all fields";
        this.response.status = "03";
        $("#loader").hide();
      } else {
        const vm = this;
        baseUrl
          .post("/signup", user)
          .then((response) => {
            $("#loader").hide();
            this.response = response.data;
            if (response.data.status == "00") {
              this.email = "";
              this.password = "";
              this.fullName = "";
              this.mobileNumber = "";
              this.companyName = "";
              this.country = "";
              setTimeout(function () {
                vm.$router.push("/get-started");
              }, 3000);
            } else {
              this.response.message = response.data.message;
              this.password = "";
            }
          })
          .catch((error) => {
            $("#loader").hide();
            this.response.message = error.message;
            this.fullName = "";
            this.email = "";
            this.password = "";
            this.mobileNumber = "";
            this.companyName = "";
            this.country = "";
          });
      }
    },
    onInput(mobileNumber, phoneObject, input) {
      if (phoneObject && phoneObject.number) {
        this.mobileNumber = phoneObject.number;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Open Sans", sans-serif !important;
}

#loader {
  margin-left: 10px;
}

.auth form .form-group {
  margin-bottom: 0.8rem;
}
</style>
